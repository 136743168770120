import React from "react"
import { compose } from "recompose"
import { FormattedMessage, injectIntl } from "react-intl"
import styles from "./saas-on-premise.module.css"
import saas from "../../images/icons/saas-on-premise/saas.svg"
import onPremise from "../../images/icons/saas-on-premise/on-premise.svg"
import onPremise2 from "../../images/icons/saas-on-premise/on-premise-2.svg"
import message from "../../images/icons/saas-on-premise/message.svg"
import guarantee from "../../images/icons/saas-on-premise/guarantee.svg"
import checkmark from "../../images/icons/saas-on-premise/checkmark.svg"
import rodoImage from "../../images/icons/saas-on-premise/rodo.svg"
import BackgroundSection from "../BackgroundSection/backgroundSection"
import time from "../../images/icons/saas-on-premise/time.svg"
import UX from "../../images/icons/saas-on-premise/UX.svg"
import pack from "../../images/icons/saas-on-premise/pack.svg"
import delivery from "../../images/icons/saas-on-premise/delivery.svg"
import cart from "../../images/icons/saas-on-premise/cart.svg"
import quality from "../../images/icons/saas-on-premise/quality-data.svg"
import clients from "../../images/icons/saas-on-premise/clients.svg"
import profil from "../../images/icons/saas-on-premise/profil.svg"

import { Link } from "gatsby"

const cards = [
  {
    title: <FormattedMessage id="saas-on-premise.guarantee.card-1.title" />,
    icon: saas,
    labels: [
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-1" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-2" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-3" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-4" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-5" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-6" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-1.label-7" />
        ),
      },
    ],
    rodo: <FormattedMessage id="saas-on-premise.guarantee.card-2.rodo" />,
  },
  {
    title: <FormattedMessage id="saas-on-premise.guarantee.card-2.title" />,
    icon: onPremise2,
    labels: [
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-2.label-1" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-2.label-2" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-2.label-3" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-2.label-4" />
        ),
      },
      {
        label: (
          <FormattedMessage id="saas-on-premise.guarantee.card-2.label-5" />
        ),
      },
    ],
    rodo: <FormattedMessage id="saas-on-premise.guarantee.card-2.rodo" />,
  },
]

const perks = [
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-1" />,
    icon: time,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-2" />,
    icon: UX,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-3" />,
    icon: delivery,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-4" />,
    icon: pack,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-5" />,
    icon: cart,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-6" />,
    icon: quality,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-7" />,
    icon: clients,
  },
  {
    title: <FormattedMessage id="saas-on-premise.perks.perk-8" />,
    icon: profil,
  },
]

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768

const HeaderSaasOnPremise = ({ intl }) => (
  <div className="row">
    <div className={styles.headerWrapper}>
      <div className={styles.headerCols1}>
        <h1 className={styles.heading}>
          <FormattedMessage id="saas-on-premise.title" />
        </h1>
        <p className={styles.desc}>
          <FormattedMessage id="saas-on-premise.desc-1" />
        </p>
        <div className={styles.desc2Wrapper}>
          <div>
            <div className={styles.arrow} />
          </div>
          <p className={styles.desc}>
            <FormattedMessage id="saas-on-premise.desc-2" />
          </p>
        </div>
        <div>
          <a
            href={intl.formatMessage({ id: "saas-on-premise.button.link" })}
            className={`${styles.button} `}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="saas-on-premise.button.label" />
            <img src={message} alt="" width={24} height={24} />
          </a>
        </div>
      </div>

      <div className={styles.headerCols2}>
        <div className={`${styles.headerIcon} ${styles.headerIcon1}`}>
          <img src={saas} alt="" />
          <p>SaaS</p>
        </div>
        <div className={`${styles.headerIcon} ${styles.headerIcon2}`}>
          <img src={onPremise} alt="" />
          <p>On-Premise</p>
        </div>
      </div>
    </div>
  </div>
)

const HeaderComponent = injectIntl(HeaderSaasOnPremise)

const SaasOnPremise = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section_1}>
        {isDesktop ? (
          <BackgroundSection
            filename="banner-bg.png"
            position="right bottom"
            color="transparent"
            size="100% 100%"
          >
            <HeaderComponent />
          </BackgroundSection>
        ) : (
          <HeaderComponent />
        )}
      </section>

      <section className={`row ${styles.section_2}`}>
        <div className={styles.guarantee}>
          <img src={guarantee} alt="" />
          <p>
            <FormattedMessage id="saas-on-premise.guarantee" />
          </p>
        </div>
      </section>

      <section className={styles.section_3}>
        <div className="row">
          <div className={styles.wrapper}>
            {cards.map(({ title, icon, labels, rodo }, index) => (
              <div key={index} className={styles.card}>
                <div>
                  <div className={styles.cardHeader}>
                    <h4>{title}</h4>
                    <img src={icon} alt="" width={50} height={50} />
                  </div>
                  {labels.map(({ label }, index) => (
                    <div className={styles.cardLabels} key={index}>
                      <img src={checkmark} alt="" width={20} height={20} />
                      <p>{label}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.cardRodo}>
                  <Link to={"/privacy-policy"}>
                    <img src={rodoImage} alt="" width={20} height={20} />
                    {rodo}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.section_4}>
        <div className="row">
          <h4>
            <FormattedMessage id="saas-on-premise.perks.title" />
          </h4>
          <div className={styles.perksWrapper}>
            {perks.map(({ title, icon }, index) => (
              <div className={styles.perksCard} key={index}>
                <img src={icon} alt="" width={70} height={50} />
                <p>{title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default compose(injectIntl)(SaasOnPremise)
