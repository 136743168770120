import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/Seo/seo";
import Footer from "../components/Footer/footer";
import { GoogleFont, TypographyStyle } from "react-typography";
import typography from "../utils/typography";
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";
import SaasOnPremise from "../components/SaasOnPremise/SaasOnPremise";

class SaasOnPremisePage extends React.Component {
  render() {
    const { uri, intl } = this.props;
    return (
      <Layout location={uri} isStatic={true}>
        <SEO title={intl.formatMessage({ id: "nav.saas-on-premise" })} />
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <SaasOnPremise />
        <Footer />
      </Layout>
    );
  }
}

SaasOnPremisePage.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(SaasOnPremisePage);
